import React from "react";

function Arrow({ color }) {
	return (
		<svg width="26" height="8" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M25.3536 4.35356C25.5488 4.1583 25.5488 3.84172 25.3536 3.64645L22.1716 0.464474C21.9763 0.269212 21.6597 0.269212 21.4645 0.464474C21.2692 0.659736 21.2692 0.976318 21.4645 1.17158L24.2929 4.00001L21.4645 6.82843C21.2692 7.0237 21.2692 7.34028 21.4645 7.53554C21.6597 7.7308 21.9763 7.7308 22.1716 7.53554L25.3536 4.35356ZM0 4.50001H25V3.50001H0V4.50001Z"
				fill={color}
			/>
		</svg>
	);
}

export default Arrow;
